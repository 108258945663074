import React from 'react';

const GirlsjacketItems = () => {
    return (
        <div>
            <div className="ttm-service-single-content-area">
                <div className="row mb_15 mt_15">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Denim-Girls-Jacket-1.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-1</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Denim-Girls-Jacket-2.jpg" alt=" image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>{/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-2</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Denim-Girls-Jacket-3.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-3</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Denim-Girls-Jacket-4.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-4</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Denim-Girls-Jacket-5.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-5</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Denim-Girls-Jacket-6.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-6</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Girls-Jacket-4-300x300.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-7</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/girl's/jacket/Girls-Jacket-5.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Girl's Jacket-8</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GirlsjacketItems;