import React from 'react';
import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


const Client = () => {
    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1000,
        infinite: true,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1024,
                settings: { slidesToShow: 4 }
            },
            {
                breakpoint: 777,
                settings: { slidesToShow: 3 }
            },
            {
                breakpoint: 575,
                settings: { slidesToShow: 2 }
            },
            {
                breakpoint: 400,
                settings: { slidesToShow: 1 }
            }
        ]
    }

    return (
        <div className='clients'>
            <section className="ttm-row client-section clients clearfix">
                <div className="container">
                    <Slider {...settings}>

                        <div className="ttm-client-logo-tooltip" data-tooltip="client-01">
                            <div className="client-thumbnail">
                                <img className="img-fluid auto_size" width={160} height={30} src="images/client/global.webp" alt="image" />
                            </div>
                        </div>

                        <div className="ttm-client-logo-tooltip" data-tooltip="client-02">
                            <div className="client-thumbnail">
                                <img className="img-fluid auto_size" width={160} height={30} src="images/client/green.webp" alt="image" />
                            </div>
                        </div>


                        <div className="ttm-client-logo-tooltip" data-tooltip="client-03">
                            <div className="client-thumbnail">
                                <img className="img-fluid auto_size" width={160} height={30} src="images/client/organic.webp" alt="image" />
                            </div>
                        </div>


                        <div className="ttm-client-logo-tooltip" data-tooltip="client-04">
                            <div className="client-thumbnail">
                                <img className="img-fluid auto_size" width={160} height={30} src="images/client/stabdard.webp" alt="image" />
                            </div>
                        </div>



                        <div className="ttm-client-logo-tooltip" data-tooltip="client-01">
                            <div className="client-thumbnail">
                                <img className="img-fluid auto_size" width={160} height={30} src="images/client/textile.webp" alt="image" />
                            </div>
                        </div>


                        <div className="ttm-client-logo-tooltip" data-tooltip="client-04">
                            <div className="client-thumbnail">
                                <img className="img-fluid auto_size" width={160} height={30} src="images/client/stabdard.webp" alt="image" />
                            </div>
                        </div>
                    </Slider>

                </div>
            </section>

        </div>
    );
};

export default Client;