import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './Layout/Main';
import Home from './Pages/Home';
import Contact from './Pages/ContactUs';
import Aboutus from './Pages/Aboutus';
import LifeWithUs from './Pages/LifeWithUs';
import Blog from './Pages/Blog';
import MensJeans from './Components/Products/Mens/MensJeans/MensJeans';
import ContactUs from './Pages/ContactUs';
import MensShirts from './Components/Products/Mens/MensShitrs.jsx/MensShirts';
import MensHoodieItem from './Components/Products/Mens/MensHoodie/MensHoodieItem';
import MensHoodie from './Components/Products/Mens/MensHoodie/MensHoodie';
import MensPolo from './Components/Products/Mens/MensPolo/MensPolo';
import MensPoloItems from './Components/Products/Mens/MensPolo/MensPoloItems';
import MensShorts from './Components/Products/Mens/MensShorts/MensShorts';
import MensSweater from './Components/Products/Mens/MensSweater/MensSweater';
import MensTshirts from './Components/Products/Mens/MensTshirts/MensTshirts';
import WomensJeans from './Components/Products/Womens/WomenJeans/WomensJeans';
import WomenLeggings from './Components/Products/Womens/WomenLeggings/WomenLeggings';
import WomensTankTop from './Components/Products/Womens/WomensTankTop/WomensTankTop';
import WomenShirts from './Components/Products/Womens/WomensShirts/WomenShirts';
import WomenTshirts from './Components/Products/Womens/WomensTshirts/WomenTshirts';
import BoysJeans from './Components/Products/Boys/BoysJeans/BoysJeans';
import BoysHoodie from './Components/Products/Boys/BoysHoodie/BoysHoodie';
import BoysShorts from './Components/Products/Boys/BoysShorts/BoysShorts';
import BoysPolo from './Components/Products/Boys/BoysPolo/BoysPolo';
import BoysSweater from './Components/Products/Boys/BoysSweater/BoysSweater';
import BoysTshirts from './Components/Products/Boys/BoysTshirts/BoysTshirts';
import GirlsJeans from './Components/Products/Girls/GirlsJeans/GirlsJeans';
import Girlsjacket from './Components/Products/Girls/GirlsJacket/Girlsjacket';
import GirlsTshirts from './Components/Products/Girls/GirlsTshirts/GirlsTshirts';
import Baby from './Components/Products/Babys/Baby';
import LeatherBag from './Components/Products/Accessories/LeatherBag/LeatherBag';
import LeatherShoes from './Components/Products/Accessories/LeatherShoes/LeatherShoes';
import Handicraft from './Components/Products/Accessories/HandiCraft/Handicraft';
import OurPartners from './Pages/OurPartners';
import SustainableApparel from './Pages/SustainableApparel';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Main></Main>,
    children: [
      {
        path: '/',
        element: <Home></Home>
      },
      {
        path: '/aboutus',
        element: <Aboutus></Aboutus>

      },
      {
        path: '/lifewithus',
        element: <LifeWithUs></LifeWithUs>
      },

      {
        path: '/mensjeans',
        element: <MensJeans></MensJeans>
      },

      {
        path: '/menshirt',
        element: <MensShirts></MensShirts>
      },
      {
        path: '/menshoodie',
        element: <MensHoodie></MensHoodie>
      },
      {
        path: '/menspolo',
        element: <MensPolo></MensPolo>
      },
      {
        path: '/mensshorts',
        element: <MensShorts></MensShorts>

      },
      {
        path: '/mensweater',
        element: <MensSweater></MensSweater>
      },
      {
        path: '/mentshirts',
        element: <MensTshirts></MensTshirts>
      },
      {
        path: '/womensjeans',
        element: <WomensJeans></WomensJeans>
      },
      {
        path: '/womenleggings',
        element: <WomenLeggings />
      },
      {
        path: '/womentanktop',

        element: <WomensTankTop />
      },
      {
        path: '/womenshirts',
        element: <WomenShirts />
      },
      {
        path: '/womentshirts',
        element: <WomenTshirts />
      },
      {
        path: '/boysjeans',
        element: <BoysJeans />
      },
      {
        path: '/boyshoodie',
        element: <BoysHoodie />
      },
      {
        path: '/boyshorts',
        element: <BoysShorts />
      },
      {
        path: '/boyspolo',
        element: <BoysPolo />
      },
      {
        path: '/boysweater',
        element: <BoysSweater />
      },
      {
        path: '/boystshirts',
        element: <BoysTshirts />
      },
      {
        path: '/girlsjeans',
        element: <GirlsJeans />
      },
      {
        path: '/girljacket',
        element: <Girlsjacket />
      },
      {
        path: '/girlstshirts',
        element: <GirlsTshirts />
      },
      {
        path: '/babysapparels',
        element: <Baby />
      },
      {
        path: '/accessories',
        element: <LeatherBag />
      },
      {
        path: '/leatherbelt&shoes',
        element: <LeatherShoes />
      },
      {
        path: '/handicraft',
        element: <Handicraft />
      },
      {
        path: '/ourpartners',
        element: <OurPartners></OurPartners>
      },
      {
        path: '/sustainableapparels',
        element: <SustainableApparel></SustainableApparel>
      },

      {
        path: '/blog',
        element: <Blog></Blog>
      },
      {
        path: '/contact',
        element: <ContactUs></ContactUs>
      }

    ]
  }

])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>

  </React.StrictMode>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
