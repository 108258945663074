import React from 'react';
import { NavLink } from 'react-router-dom';

const AboutUsBanner = () => {
    return (
        <div>
            <div className="banner_slider">
                <div className="slide " style={{ height: 400 }}>
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/4.png)' }}>
                    </div>
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 ">
                                    <div className="slide__content--headings ttm-textcolor-white aboutus-h2" >
                                        <h2 data-animation="fadeInDown" > <strong>YOUR IMAGINATION IS OUR PREVIEW</strong>
                                        </h2>
                                        <h5 className='about_brief'>A brief summarization of our company profile and how we serve our clients.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AboutUsBanner;


