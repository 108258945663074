import React from 'react';

const MensSweaterItems = () => {
    return (
        <div>
            <div className="ttm-service-single-content-area">
                <div className="row mb_15 mt_15">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-1.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-1</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-2.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>{/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-2</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-3.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                                {/* ttm-box-view-overlay end*/}
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Men's Sweater-3</h3>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end*/}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-4.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-4</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-5.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-5</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-6.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-6</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-7.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-7</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-8.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-8</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-9.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-9</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-10.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-10</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-11.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-11</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/sweater/Mens-Sweaters-12.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Sweater-12</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MensSweaterItems;