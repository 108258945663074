import React from 'react';

const WomenShirtsItems = () => {
    return (
        <div>
            <div className="ttm-service-single-content-area">
                <div className="row mb_15 mt_15">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Button-Down-Shirt-with-Puff-Sleeves.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Button Down Shirt with Puff Sleeves</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Checked-Shirt-with-Patch-Pocket-1-1.jpg" alt=" image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>{/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Checked Shirt with Patch Pocket-1</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Checked-Shirt-with-Roll-Tab-Sleeves.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Checked Shirt with Roll Tab Sleeves</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Classic-Shirt-With-Mandarin-Collar.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                                {/* ttm-box-view-overlay end*/}
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Classic Shirt With Mandarin Collar</h3>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end*/}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Colourblock-Denim-Shirt-with-Patch-Pocket.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Colourblock Denim Shirt with Patch Pocket</h3>
                                </div>
                            </div>
                        </div>
                        {/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Denim-Shirt-with-Flap-Button-Pockets.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Denim Shirt with Flap Button Pockets</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Denim-Shirt-with-Patch-Pocket.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Denim Shirt with Patch Pocket</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Denim-Shirt-with-Patch-Pockets-1.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Denim Shirt with Patch Pockets-1</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Denim-Shirt-with-Patch-Pockets.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Denim Shirt with Patch Pockets</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Lightly-Washed-Spread-Collar-Shirt.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Lightly Washed Spread Collar Shirt</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Long-Shirt-with-Flap-Pockets.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Long Shirt with Flap Pockets</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Loose-Fit-Shirt-with-Flap-Pocket.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Loose Fit Shirt with Flap Pocket</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Military-Spread-Collar-Shirt-with-Flap-Pockets.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Military Spread Collar Shirt with Flap Pockets</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Ombre-dyed-Short-Sleeves-Shirt.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Ombre dyed Short Sleeves Shirt</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Shirt-with-Buttoned-Flap-Pockets.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>&gt;
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Shirt with Buttoned Flap Pockets</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Solid-Shirt-with-Spread-Collar.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Solid Shirt with Spread Collar</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Standard-Shirt.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Standard Shirt</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Striped-Shirt-with-Spread-Collar.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Striped Shirt with Spread Collar</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Striped-Tailored-Fit-Shirt.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Striped Tailored Fit Shirt</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/women/shirts/Studios-Cotton-Oxford-Striped-Shirt.png" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Studios Cotton Oxford Striped Shirt</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default WomenShirtsItems;