import React from 'react';

const OurClient = () => {
    return (
        <div>
            <section className="ttm-row padding_zero-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row mt_140 res-991-margin_top0 mb_35">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <img style={{ height: '100x', width: 300 }} src="images/partners/euro-logo1.png" alt />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Euro Knit Wear Ltd</h3>
                                            </div>
                                            {/* <div class="featured-desc">
                                          <p>
                                              EUROTEX KNITWEAR LTD: Where threads weave fashion, since 1998, in
                                              the heart of Nayamati.</p>
                                      </div>
                                      <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="https://www.eurotexbd.net">read more<i
                                              class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <img style={{ height: '200x', width: 180 }} src="images/partners/ddd.jpg" alt />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Amtranet Group</h3>
                                            </div>
                                            {/* <div class="featured-desc">
                                          <p>In 2006, Amtranet Group's expansion sparked GEVL's venture into the
                                              value-added market with eight manufacturing units</p>
                                      </div>
                                      <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="http://www.amtranet.com/">read more<i
                                              class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <img style={{ height: '150x', width: 100 }} src="images/partners/cropped-gani-Creation-ogo.png" alt />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Gani Creation</h3>
                                            </div>
                                            {/* <div class="featured-desc">
                                          <p>Gani Creation: BSCI-certified artistry in sustainable crafting.</p>
                                      </div>
                                      <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="https://ganicreation.ltd">read more<i
                                              class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <img style={{ height: '150x', width: 100 }} src="images/partners/new-logo.webp" alt />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Himalay Sourcing ltd</h3>
                                            </div>
                                            {/* <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="we-design-fabric-2.html">read more<i
                                              class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <img style={{ height: '250x', width: 250 }} src="images/partners/roar.webp" alt />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Roar Sweater</h3>
                                            </div>
                                            {/* <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="#">read more<i class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <img style={{ height: '80x', width: 55 }} src="images/partners/ripon.png" alt />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Ripon Knit Wears Ltd</h3>
                                            </div>
                                            {/* <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="https://www.ripongroupbd.com">read more<i
                                              class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon flaticon-placeholder" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Explore Knit Wear Ltd</h3>
                                            </div>
                                            {/* <div class="featured-desc">
                                          <p>Fablio is a leading export textile service providers globally.</p>
                                      </div>
                                      <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="we-design-fabric-2.html">read more<i
                                              class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default OurClient;