import React from 'react';

const ComplienceSection = () => {
    return (
        <div>
            <section className="ttm-row padding_zero-section ttm-bgcolor-skincolor bg-layer-equal-height clearfix">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-7 col-md-12">
                                    {/* col-img-img-two */}
                                    <div className="ttm-bg ttm-col-bgimage-yes col-bg-img-two ttm-left-span z-index-2">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                                        <div className="layer-content">
                                        </div>
                                    </div>{/* col-img-bg-img-two end*/}

                                </div>
                                <div className="col-lg-5">
                                    <div className="padding_top30 padding_bottom30 text-lg-right res-1199-padding_right15 res-991-padding_left15">
                                        {/* <div class="fs-26"></div> */}
                                        <div className="fs-26"><b>compliance and standards</b></div>
                                    </div>
                                    <div className="ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-darkgrey ttm-bg ttm-right-span spacing-3 z-index-2 h-auto ">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                            <div className="ttm-col-wrapper-bg-layer-inner" />
                                        </div>
                                        <div className="layer-content">
                                            {/* section title */}
                                            <div className="section-title">
                                                <div className="title-header">
                                                    <h2 className="title">We comply with following <b>compliance and
                                                        standards
                                                    </b></h2>
                                                </div>
                                            </div>{/* section title end */}
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    {/*featured-icon-box*/}
                                                    <div className="featured-icon-box icon-align-before-title style2">
                                                        <div className="featured-icon">
                                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                                <div className="fa fa-check" />
                                                            </div>
                                                        </div>
                                                        <div className="featured-title">
                                                            <h3><a href="https://www.oeko-tex.com"><img className="img-fluid auto_size" style={{ width: 100, height: 35 }} src="images/client/green.webp" alt="image" /></a>
                                                            </h3>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-desc">
                                                                <p>OEKO-TEX® standards enable everyone to make
                                                                    responsible decisions and protect natural
                                                                    resources.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>{/* featured-icon-box end*/}
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    {/*featured-icon-box*/}
                                                    <div className="featured-icon-box icon-align-before-title style2 ">
                                                        <div className="featured-icon">
                                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                                <div className="fa fa-check" />
                                                            </div>
                                                        </div>
                                                        <div className="featured-title">
                                                            <h3><a href="https://global-standard.org/"><img className="img-fluid auto_size" style={{ width: 40, height: 35 }} src="images/client/bsci.png" alt="image" />
                                                            </a>
                                                            </h3>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-desc">
                                                                <p>The Business Social Compliance Initiative (BSCI)
                                                                    is a
                                                                    non-profit organisation.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* featured-icon-box end*/}
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    {/*featured-icon-box*/}
                                                    <div className="featured-icon-box icon-align-before-title style2 ">
                                                        <div className="featured-icon">
                                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                                <div className="fa fa-check" />
                                                            </div>
                                                        </div>
                                                        <div className="featured-title">
                                                            <h3><a href="https://www.oeko-tex.com/en/"><img className="img-fluid auto_size" style={{ width: 35, height: 30 }} src="images/client/sedax.png" alt="image" /></a>
                                                            </h3>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-desc">
                                                                <p>Sedex's technology, data insights and services
                                                                    help
                                                                    companies build socially and environmentally
                                                                    sustainable businesses and supply chains
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>{/* featured-icon-box end*/}
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    {/*featured-icon-box*/}
                                                    <div className="featured-icon-box icon-align-before-title style2 ">
                                                        <div className="featured-icon">
                                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                                <div className="fa fa-check" />
                                                            </div>
                                                        </div>
                                                        <div className="featured-title">
                                                            <h3><a href="https://global-standard.org/"><img className="img-fluid auto_size" style={{ width: 60, height: 35 }} src="images/client/rsc.jpg" alt="image" /></a>
                                                            </h3>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-desc">
                                                                <p>The RSC conducts structural, electrical, fire and
                                                                    boiler safety inspections, supports and monitors
                                                                    remediation,
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* featured-icon-box end*/}
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    {/*featured-icon-box*/}
                                                    <div className="featured-icon-box icon-align-before-title style2 res-767-padding_left0">
                                                        <div className="featured-icon">
                                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                                <div className="fa fa-check" />
                                                            </div>
                                                        </div>
                                                        <div className="featured-title">
                                                            <h3><a href="https://global-standard.org/">
                                                                <img className="img-fluid auto_size" style={{ width: 70, height: 25 }} src="images/client/accrod1.png" alt="image" /></a>
                                                            </h3>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-desc">
                                                                <p>The Accord is an independent agreement designed
                                                                    to
                                                                    make all garment factories in Bangladesh safe
                                                                    workplaces.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* featured-icon-box end*/}
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    {/*featured-icon-box*/}
                                                    <div className="featured-icon-box icon-align-before-title style2 res-767-padding_left0">
                                                        <div className="featured-icon">
                                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                                <div className="fa fa-check" />
                                                            </div>
                                                        </div>
                                                        <div className="featured-title">
                                                            <h3><a href="https://global-standard.org/">
                                                                <img className="img-fluid auto_size" style={{ width: 80, height: 35 }} src="images/client/organic.webp" alt="image" /></a>
                                                            </h3>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-desc">
                                                                <p>Our Label Check gives you the opportunity to
                                                                    check
                                                                    the validity of OEKO-TEX® certificates online at
                                                                    any
                                                                    time.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* featured-icon-box end*/}
                                                </div>
                                            </div>
                                            <div className="row">
                                            </div>
                                            {/* <div class="ttm-horizontal_sep width-100 margin_top20 margin_bottom20">
                                      </div> */}
                                            <div className="padding_top15">
                                                {/* ttm-progress-bar */}
                                                {/* <div class="ttm-progress-bar" data-percent="90%">
                                              <div class="progressbar-title">Textile</div>
                                              <div class="progress-bar-inner">
                                                  <div class="progress-bar progress-bar-color-bar_skincolor">
                                                      <div class="progress-bar-percent ttm-bgcolor-skincolor"
                                                          data-percentage="90">90%</div>
                                                  </div>
                                              </div>
                                          </div> */}
                                                {/* ttm-progress-bar end */}
                                                {/* ttm-progress-bar */}
                                                {/* <div class="ttm-progress-bar clearfix" data-percent="80%">
                                              <div class="progressbar-title">Manufacture</div>
                                              <div class="progress-bar-inner">
                                                  <div class="progress-bar progress-bar-color-bar_skincolor">
                                                      <div class="progress-bar-percent ttm-bgcolor-skincolor"
                                                          data-percentage="80">80%</div>
                                                  </div>
                                              </div>
                                          </div> */}
                                                {/* ttm-progress-bar end */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* row end */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ComplienceSection;