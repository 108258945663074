import React from 'react';

const MensShortsItems = () => {
    return (
        <div>
            <div className="ttm-service-single-content-area">
                <div className="row mb_15 mt_15">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-1.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-1</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-2.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>{/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Mens-Shorts-2</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-3.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-3</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-4.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-4</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-5.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-5</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-6.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-6</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-7.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-7</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-8.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-8</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-9.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-9</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-10.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-10</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-11.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-11</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                            {/* ttm-box-view-overlay */}
                            <div className="ttm-box-view-overlay">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/menjeans/shorts/Mens-Shorts-12.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="ttm-media-link">
                                    <a href="/contact">
                                        <i className="fa fa-search" />
                                    </a>
                                </div>
                            </div>
                            {/* ttm-box-view-overlay end*/}
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Men's Shorts-12</h3>
                                </div>
                            </div>
                        </div>{/* featured-icon-box end*/}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MensShortsItems;