import React from 'react';

const OurCertificate = () => {
    return (
        <div>
            <section className="ttm-row about-section  clearfix">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="row">
                                <div style={{ marginTop: 10, marginLeft: 20 }} className="col-lg-6 col-md-12 col-xs-12 ">
                                    <div className=" res-991-padding_top40">
                                        {/* section title */}
                                        <div className="section-title">
                                            <div className="title-header">
                                                <h3 style={{ fontSize: 30 }}>Our Certifications</h3>
                                            </div>
                                            <div className="title-desc">
                                                <h5>The certifications we have attained for our supplier base, products
                                                    and practices will ensure that your brand is sourcing using the best
                                                    partners and techniques.
                                                </h5>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="layer-content">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                {/*featured-icon-box*/}
                                                <div className="featured-icon-box icon-align-top-content style3 " style={{ backgroundColor: '#c3002f' }}>
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt  ttm-icon_element-size-md">
                                                            <img className="img-fluid auto_size" width={160} height={30} src="images/client/global.webp" alt="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* featured-icon-box end*/}
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                {/*featured-icon-box*/}
                                                <div className="featured-icon-box icon-align-top-content style3" style={{ backgroundColor: '#c3002f' }}>
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                            <img className="img-fluid auto_size" width={160} height={30} src="images/client/green.webp" alt="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* featured-icon-box end*/}
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                {/*featured-icon-box*/}
                                                <div className="featured-icon-box icon-align-top-content style3" style={{ backgroundColor: '#c3002f' }}>
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                            <img className="img-fluid auto_size" width={160} height={30} src="images/client/organic.webp" alt="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* featured-icon-box end*/}
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                {/*featured-icon-box*/}
                                                <div className="featured-icon-box icon-align-top-content style3" style={{ backgroundColor: '#c3002f' }}>
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                            <img className="img-fluid auto_size" width={160} height={30} src="images/client/stabdard.webp" alt="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* featured-icon-box end*/}
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                {/*featured-icon-box*/}
                                                <div className="featured-icon-box icon-align-top-content style3" style={{ backgroundColor: '#c3002f' }}>
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                            <img className="img-fluid auto_size" width={160} height={30} src="images/client/textile.webp" alt="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* featured-icon-box end*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </div>
            </section>

        </div>
    );
};

export default OurCertificate;